import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const AddDescription = () => {
  const { domain } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [matrikelnummer, setMatrikelnummer] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const newDescription = {
      domain,
      description,
      author: email,
      email,
      matrikelnummer,
    };

    fetch('http://localhost:4000/api/descriptions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newDescription),
    })
    .then(response => response.json())
    .then(() => {
      navigate(`/description/${domain}`);
    })
    .catch(err => console.error('Error adding description:', err));
  };

  return (
    <div>
      <h1>Beschreibung für {domain} hinzufügen</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">E-Mail:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="matrikelnummer">Matrikelnummer:</label>
          <input
            type="text"
            id="matrikelnummer"
            value={matrikelnummer}
            onChange={(e) => setMatrikelnummer(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Beschreibung:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="4"
            required
          />
        </div>
        <button type="submit">Eintrag erstellen</button>
      </form>
    </div>
  );
};

export default AddDescription;
