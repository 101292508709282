import React from 'react';

const jsonObject = {
  domains: [
    "timebuffer.nl", "handelsstrasse.com", "gülümsekaderine.com", "handelsstrasse.dev", 
    "üçüncü.com", "xpmouse.uk", "xpcount.com", "timebuffer.us", "wallsofnature.com",
    "timebuffer.io", "whitelarry.com", "timebuffer.es",
    "timebuffer.dev", "öpbak.com", "welthandelsplatz.dev", "timebuffer.eu",
    "timebuffer.net", "timebuffer.de", "timestamp.id", "timebuffer.sk",
    "schnittholzlager.de", "xelated.com"
  ]
};

const JsonPage = () => {
  return (
    <div className="container mt-4">
      <h1>JSON Object of Domains (to actualize)</h1>
      <pre>{JSON.stringify(jsonObject, null, 2)}</pre>
    </div>
  );
};

export default JsonPage;
