import React, { useState, useEffect } from 'react';
import './BuchstabenSalat.css';

const BuchstabenSalat = ({ domains = [] }) => {
  const [letters, setLetters] = useState([]);
  const [hoveredLetter, setHoveredLetter] = useState(null);

  useEffect(() => {
    if (domains.length > 0) {
      // Generate random letters and positions
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      const randomLetters = domains.map((domain) => {
        const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
        return {
          letter: randomLetter,
          domain: domain.name,
          link: domain.link,
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight,
        };
      });
      setLetters(randomLetters);
    }
  }, [domains]);

  const handleMouseEnter = (index) => {
    setHoveredLetter(index);
  };

  const handleMouseLeave = () => {
    setHoveredLetter(null);
  };

  return (
    <div className="salat-container">
      {letters.map((letterData, index) => (
        <div
          key={index}
          className="salat-letter"
          style={{
            left: `${letterData.x}px`,
            top: `${letterData.y}px`,
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {letterData.letter}
          {hoveredLetter === index && (
            <a
              href={letterData.link}
              className="salat-link"
              style={{ fontSize: '2rem', marginLeft: '20px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {letterData.domain} {/* Slice to remove the first letter */}
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

export default BuchstabenSalat;
