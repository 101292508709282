import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import DomainTable from './components/DomainTable';
import BuchstabenSalat from './components/Buchstabensalat';
import DomainDescription from './components/DomainDescription';
import AddDescription from './components/AddDescription';
import BillUpload from './components/BillUpload';
import NavBar from './components/NavBar';

import JsonPage from './components/JsonPage';  // New Page for JSON Object

import './App.css'; // Assuming you're adding the styles here

import 'bootstrap/dist/css/bootstrap.min.css';
import FloatingActionButton from './components/FloatingActionButton';

/*
<li className="nav-item">
                  <Link className="nav-link" to="/upload-bill">Upload Bill</Link>
                </li>

                <Route path="/upload-bill" element={<BillUpload />} />
                <FloatingActionButton />
*/
const domains = [
  { name: '365ects.com', info: 'A platform for educational tools.', link: 'http://365ects.com', collection: 'shared-node-app' },
  { name: '365jects.com', info: 'Educational project management tool.', link: 'http://365jects.com', collection: 'shared-node-app' },
  { name: 'alignvisit.com', info: 'Online scheduling platform for visits.', link: 'http://alignvisit.com', collection: 'forensik.cc' },
  { name: 'alorsondanse.net', info: 'A network for dance enthusiasts.', link: 'http://alorsondanse.net', collection: 'shared-node-app' },
  { name: 'apparrivederci.com', info: 'Travel booking and farewell service.', link: 'http://apparrivederci.com', collection: 'smartbroad.net' },
  { name: 'applicationbroadcast.com', info: 'Broadcasting job applications.', link: 'http://applicationbroadcast.com', collection: 'shared-node-app' },
  { name: 'broad2broad.com', info: 'A broadcasting service platform.', link: 'http://broad2broad.com', collection: 'broad2broad.com' },
  { name: 'callmeup.io', info: 'A modern communication tool.', link: 'http://callmeup.io', collection: 'shared-node-app' },
  { name: 'certableau.com', info: 'Certification management platform.', link: 'http://certableau.com', collection: 'shared-node-app' },
  { name: 'certtable.com', info: 'Educational certifications platform.', link: 'http://certtable.com', collection: 'shared-node-app' },
  { name: 'chaircount.com', info: 'Meeting room booking and management.', link: 'http://chaircount.com', collection: 'chaircount.com' },
  { name: 'ciaorelated.com', info: 'Social media for international connections.', link: 'http://ciaorelated.com', collection: 'chaircount.com' },
  { name: 'copsareprayerstoo.com', info: 'Community for law enforcement supporters.', link: 'http://copsareprayerstoo.com', collection: 'shared-node-app' },
  { name: 'cosmetrics.io', info: 'Cosmetic products analytics.', link: 'http://cosmetrics.io', collection: 'shared-node-app' },
  { name: 'countestate.com', info: 'Real estate listings and analysis.', link: 'http://countestate.com', collection: 'shared-node-app' },
  { name: 'counteverest.com', info: 'Mountaineering tracking and expeditions.', link: 'http://counteverest.com', collection: 'shared-node-app' },
  { name: 'cutsimulation.com', info: 'Simulation software for cutting technologies.', link: 'http://cutsimulation.com', collection: 'broad2broad.com' },
  { name: 'datacity.wien', info: 'Smart city platform for Vienna.', link: 'http://datacity.wien', collection: 'shared-node-app' },
  { name: 'devitable.com', info: 'A collaborative software development platform.', link: 'http://devitable.com', collection: 'smartbroad.net' },
  { name: 'domainsurvey.eu', info: 'A domain survey tool for Europe.', link: 'http://domainsurvey.eu', collection: 'shared-node-app' },
  { name: 'domainsurvey.net', info: 'Your domain survey tool.', link: 'http://domainsurvey.net', collection: 'domainsurvey.net' },
  { name: 'emanuration.com', info: 'A personal growth and development platform.', link: 'http://emanuration.com', collection: 'shared-node-app' },
  { name: 'erstebahn.com', info: 'A railway and transportation company.', link: 'http://erstebahn.com', collection: 'shared-node-app' },
  { name: 'evereast.app', info: 'A task management and productivity app.', link: 'http://evereast.app', collection: 'shared-node-app' },
  { name: 'evereast.ch', info: 'A Swiss-based online service platform.', link: 'http://evereast.ch', collection: 'shared-node-app' },
  { name: 'evereast.cloud', info: 'Cloud hosting services for enterprises.', link: 'http://evereast.cloud', collection: 'shared-node-app' },
  { name: 'forensik.cc', info: 'Forensic analysis and research community.', link: 'http://forensik.cc', collection: 'smartbroad.net' },
  { name: 'grewupatmidnight.com', info: 'Online community for night owls.', link: 'http://grewupatmidnight.com', collection: 'shared-node-app' },
  { name: 'hashtaghymn.com', info: 'Music and lyric sharing platform.', link: 'http://hashtaghymn.com', collection: 'forensik.cc' },
  { name: 'holdbacktheriver.com', info: 'Adventure and water sports community.', link: 'http://holdbacktheriver.com', collection: 'shared-node-app' },
  { name: 'incmic.com', info: 'Podcasting platform for influencers.', link: 'http://incmic.com', collection: 'forensik.cc' },
  { name: 'incvoice.com', info: 'Voice recording and transcription services.', link: 'http://incvoice.com', collection: 'custom' },
  { name: 'ingpub.com', info: 'A publishing platform for indie writers.', link: 'http://ingpub.com', collection: 'forensik.cc' },
  { name: 'kannschonsein.com', info: 'An online blogging platform.', link: 'http://kannschonsein.com', collection: 'shared-node-app' },
  { name: 'ksoik.com', info: 'Technology solutions for small businesses.', link: 'http://ksoik.com', collection: 'smartbroad.net' },
  { name: 'kurcalama.com', info: 'Marketing and digital solutions.', link: 'http://kurcalama.com', collection: 'shared-node-app' },
  { name: 'lainted.com', info: 'Fashion and lifestyle e-commerce.', link: 'http://lainted.com', collection: 'shared-node-app' },
  
  // Domains with a specific collection
  { name: 'lescreps.com', info: 'Crepe recipes and cooking tips.', link: 'http://lescreps.com', collection: 'smartbroad.net' },
  { name: 'lierrex.com', info: 'Real estate investment platform.', link: 'http://lierrex.com', collection: 'shared-node-app' },
  { name: 'mainted.com', info: 'Maintenance and repair services.', link: 'http://mainted.com', collection: 'shared-node-app' },
  { name: 'manuration.com', info: 'HR and payroll management solutions.', link: 'http://manuration.com', collection: 'shared-node-app' },
  { name: 'myflashes.com', info: 'Online portfolio for photographers.', link: 'http://myflashes.com', collection: 'custom' },
  { name: 'ndelier.com', info: 'A platform for creative designers.', link: 'http://ndelier.com', collection: 'shared-node-app' },
  { name: 'noisyshift.com', info: 'Music and sound production platform.', link: 'http://noisyshift.com', collection: 'shared-node-app' },
  { name: 'nsteuer.com', info: 'Tax calculation and filing service.', link: 'http://nsteuer.com', collection: 'shared-node-app' },
  { name: 'oepbak.com', info: 'Online bakery ordering system.', link: 'http://oepbak.com', collection: 'songverwandt.com' },
  { name: 'openedbills.com', info: 'Invoice and billing management platform.', link: 'http://openedbills.com', collection: 'shared-node-app' },
  { name: 'phository.com', info: 'Photography sharing platform.', link: 'http://phository.com', collection: 'forensik.cc' },
  { name: 'pinnwand.bio', info: 'Bulletin board for biology enthusiasts.', link: 'http://pinnwand.bio', collection: 'shared-node-app' },
  { name: 'pinnwand.cloud', info: 'Bulletin board for cloud computing.', link: 'http://pinnwand.cloud', collection: 'shared-node-app' },
  { name: 'pinnwand.dev', info: 'Bulletin board for developers.', link: 'http://pinnwand.dev', collection: 'custom' },
  { name: 'qapp.at', info: 'A mobile application for quick tasks.', link: 'http://qapp.at', collection: 'shared-node-app' },
  { name: 'qrown.bio', info: 'Biotech industry insights and analytics.', link: 'http://qrown.bio', collection: 'shared-node-app' },
  { name: 'qtab.bio', info: 'Biotechnology product management platform.', link: 'http://qtab.bio', collection: 'shared-node-app' },
  { name: 'qtab.cloud', info: 'Cloud-based product management.', link: 'http://qtab.cloud', collection: 'shared-node-app' },
  { name: 'qtab.dev', info: 'Developer tools for product management.', link: 'http://qtab.dev', collection: 'shared-node-app' },
  { name: 'restmüll.net', info: 'Waste management services platform.', link: 'http://restmüll.net', collection: 'shared-node-app' },
  { name: 'schnittholzlager.at', info: 'Lumber and wood storage solutions.', link: 'http://schnittholzlager.at', collection: 'shared-node-app' },
  { name: 'schnittholzlager.ch', info: 'Swiss lumber storage solutions.', link: 'http://schnittholzlager.ch', collection: 'shared-node-app' },
  { name: 'skincaringfactory.com', info: 'Skin care product manufacturing.', link: 'http://skincaringfactory.com', collection: 'forensik.cc' },
  { name: 'smartbroad.cloud', info: 'Broadband solutions for enterprises.', link: 'http://smartbroad.cloud', collection: 'shared-node-app' },
  { name: 'smartbroad.io', info: 'Internet service for broadband.', link: 'http://smartbroad.io', collection: 'shared-node-app' },
  { name: 'smartbroad.net', info: 'Broadband service network.', link: 'http://smartbroad.net', collection: 'smartbroad.net' },
  { name: 'smartbroad.org', info: 'Nonprofit broadband services.', link: 'http://smartbroad.org', collection: 'shared-node-app' },
  { name: 'songrelated.com', info: 'Music streaming and song sharing.', link: 'http://songrelated.com', collection: 'smartbroad.net' },
  { name: 'songverwandt.com', info: 'Music and song association platform.', link: 'http://songverwandt.com', collection: 'songverwandt.com' },
  { name: 'spgln.com', info: 'A sharing platform for professionals.', link: 'http://spgln.com', collection: 'shared-node-app' },
  { name: 'spgln.io', info: 'Collaboration tools for professionals.', link: 'http://spgln.io', collection: 'shared-node-app' },
  { name: 'spieglein.io', info: 'Interactive mirror technology.', link: 'http://spieglein.io', collection: 'custom' },
  { name: 'ssdbasket.com', info: 'A marketplace for SSD storage.', link: 'http://ssdbasket.com', collection: 'shared-node-app' },
  { name: 'swarm-networks.com', info: 'Networking solutions for decentralized systems.', link: 'http://swarm-networks.com', collection: 'chaircount.com' },
  { name: 'timebuffer.app', info: 'A time management and scheduling app.', link: 'http://timebuffer.app', collection: 'shared-node-app' },
  { name: 'timebuffer.at', info: 'Austrian time management services.', link: 'http://timebuffer.at', collection: 'shared-node-app' },
  { name: 'timebuffer.bio', info: 'Biotech time management tools.', link: 'http://timebuffer.bio', collection: 'shared-node-app' },
  { name: 'timebuffer.ch', info: 'Swiss time management and scheduling.', link: 'http://timebuffer.ch', collection: 'shared-node-app' },
  { name: 'timestamp.bio', info: 'Biotech industry timestamping platform.', link: 'http://timestamp.bio', collection: 'shared-node-app' },
  { name: 'trendcapped.com', info: 'Trend analysis and market research.', link: 'http://trendcapped.com', collection: 'forensik.cc' },
  { name: 'tupace.com', info: 'Social media for music artists.', link: 'http://tupace.com', collection: 'songverwandt.com' },
  { name: 'uecuencue.com', info: 'A creative community platform.', link: 'http://uecuencue.com', collection: 'shared-node-app' },
];


function App() {
  return (
    <div >
    <BuchstabenSalat /> 
      
    <Router>
      <div className="App app-background" >
      
      
      <BuchstabenSalat domains={domains} />
      
        <Routes>
          <Route path="/" element={<DomainTable domains={domains}/>} />
          <Route path="/description/:domain" element={<DomainDescription />} />
          <Route path="/add-description/:domain" element={<AddDescription />} />
          <Route path="/json" element={<JsonPage />} /> {/* New Route */}
        </Routes>

      </div>
    </Router>
    </div>
  );
}

export default App;
