import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Stripes from './Stripes'; // Ensure the correct import
import BuchstabenSalat from './Buchstabensalat'; // Ensure the correct import

import './DomainTable.css'; // New CSS file for table styling

import './NavBar.css'; // New CSS file for table styling
const DomainTable = ({ domains = [] }) => {
  
  /*
  <td>
                <Link to={`/description/${domain.name}`}>
                  View Descriptions
                </Link>
              </td>
  */

              const stripeLinks = [
                { label: "Example Link 1", url: "https://example1.com" },
                { label: "Example Link 2", url: "https://example2.com" },
                { label: "Example Link 3", url: "https://example3.com" },
                { label: "Example Link 4", url: "https://example4.com" },
                { label: "Example Link 5", url: "https://example5.com" },
              ];
              const renderStripes = () => {
                return stripeLinks.map((link, index) => (
                  <div className="stripe" key={index} style={{ left: `${index * 60}px`, height: '50vh' }}>
                    <span className="stripe-link">
                      <a href={link.url} target="_blank" rel="noopener noreferrer">
                        {link.label}
                      </a>
                    </span>
                  </div>
                ));
              };
              
 const groupedDomains = domains.reduce((groups, domain) => {
    // Group by the first part of the domain name (before the first dot)
    const groupName = domain.name.split('.')[0];
    if (!groups[groupName]) {
      groups[groupName] = [];
    }
    groups[groupName].push(domain);
    return groups;
  }, {});

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      SSL is currently {props.status}
    </Tooltip>
  );
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  
  return (
    <div className="container mt-4" style={{marginRight: '50px',marginLeft: '240px', zIndex:'11' }}>
      <nav className="navbar navbar-expand-lg  ">
        <div className="container-fluid">
          {/* Navbar Title */}
          <Link className="navbar-brand h1" to="/">Domain Survey</Link>
          
          <div className={`navbar-collapse ${menuOpen ? 'show' : ''}`}  id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/json">JSON Page</Link> {/* New Link */}
              </li>
            </ul>

            {/* Powered by Smartbroad.net */}
            <span className="navbar-text ms-auto">
              Powered by Smartbroad.net
            </span>
          </div>
        </div>
      </nav>

      <div style={{ height: '400px', overflowY: 'scroll', padding:'60px', paddingTop:'10px' }}>
          <table className="table table-stripes">
            <thead>
              <tr>
                <th>Group</th>
                <th>Count</th> {/* Add a new header for the count */}
                <th>Domain</th>
                <th>Collection</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
            {Object.keys(groupedDomains).map((groupName, groupIndex) => (
              <React.Fragment key={groupIndex}>
              {groupedDomains[groupName].map((domain, index) => (
                <tr key={index}>
                  {index === 0 ? (
    <>
      <td rowSpan={groupedDomains[groupName].length}>
        {groupName}
      </td>
      <td rowSpan={groupedDomains[groupName].length}>
        {groupedDomains[groupName].length}
      </td>
    </>
  ) : null}
  <td>{domain.name}</td>
    <td>
      <span
        className={
          domain.collection === 'custom'
            ? 'text-primary fw-bold'
            : domain.collection === 'smartbroad.net'
            ? 'text-warning fw-bold'
            : domain.collection === 'forensik.cc'
            ? 'text-danger'
            : domain.collection === 'swarm-networks.com'
            ? 'text-info fw-bold'
            : domain.collection === 'domainsurvey.net'
            ? 'text-secondary fw-bold'
            : domain.collection === 'broad2broad.com'
            ? 'text-dark fw-bold'
            : domain.collection === 'songverwandt.com'
            ? 'text-muted fw-bold'
            : domain.collection === 'ksoik.com'
            ? 'text-success fw-bold'
            : domain.collection === 'xpmouse.com'
            ? 'text-success'
            : 'text-success' // default class for shared-node-app or other types
        }
      >
        {domain.collection}
      </span>
    </td>
    <td>
      <a href={domain.link} target="_blank" rel="noopener noreferrer">
        {domain.link}
      </a>
    </td>
                </tr>
              ))}
              </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
    </div>
  );
};

export default DomainTable;
