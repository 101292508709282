import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const DomainDescription = () => {
  const { domain } = useParams();
  const [descriptions, setDescriptions] = useState([]);
  const [editing, setEditing] = useState(null);
  const [editText, setEditText] = useState('');
  const [newDescription, setNewDescription] = useState('');
  useEffect(() => {
    fetch(`http://localhost:4000/api/descriptions?domain=${domain}`)
      .then(response => response.json())
      .then(data => {
        // Sort descriptions based on upvotes - downvotes
        const sortedData = data.sort((a, b) => (b.upvotes - b.downvotes) - (a.upvotes - a.downvotes));
        setDescriptions(sortedData);
      })
      .catch(err => console.error('Error fetching descriptions:', err));
  }, [domain]);

  const handleAddDescription = () => {
    fetch(`http://localhost:4000/api/descriptions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ domain, description: newDescription, email: 'test@example.com', author: 'Anonymous' }),
    })
    .then(response => response.json())
    .then(newDesc => {
      const updatedDescriptions = [...descriptions, newDesc].sort((a, b) => b.upvotes - a.upvotes);
      setDescriptions(updatedDescriptions);
      setNewDescription(''); // Clear the input field
    })
    .catch(err => console.error('Error adding description:', err));
  };

  const handleVote = (id, type) => {
    fetch(`http://localhost:4000/api/descriptions/vote/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type }),
    })
    .then(response => response.json())
    .then(updatedDescription => {
      setDescriptions(descriptions.map(desc => 
        desc._id === updatedDescription._id ? updatedDescription : desc
      ).sort((a, b) => (b.upvotes - b.downvotes) - (a.upvotes - a.downvotes)));
    })
    .catch(err => console.error('Error voting:', err));
  };

  const handleDelete = (id) => {
    fetch(`http://localhost:4000/api/descriptions/${id}`, {
      method: 'DELETE',
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to delete the description');
      }
      // Filter out the deleted description from the state
      setDescriptions(descriptions.filter(desc => desc._id !== id));
    })
    .catch(err => console.error('Error deleting description:', err));
  };

  const handleEdit = (id) => {
    setEditing(id);
    const descToEdit = descriptions.find(desc => desc._id === id);
    setEditText(descToEdit.description);
  };

  const handleEditSubmit = (id) => {
    fetch(`http://localhost:4000/api/descriptions/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ description: editText }),
    })
    .then(response => response.json())
    .then(updatedDescription => {
      setDescriptions(descriptions.map(desc => 
        desc._id === updatedDescription._id ? updatedDescription : desc
      ));
      setEditing(null);
      setEditText('');
    })
    .catch(err => console.error('Error editing description:', err));
  };

  return (
    <div>
      <h1>Beschreibung für {domain}</h1>
      <div className="description-section">
        <h2>Vorhandene Beschreibungen</h2>
        {descriptions.map((desc) => (
          <div key={desc._id} className="description">
            {editing === desc._id ? (
              <div>
                <textarea
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                  rows="4"
                />
                <button onClick={() => handleEditSubmit(desc._id)}>Save</button>
                <button onClick={() => setEditing(null)}>Cancel</button>
              </div>
            ) : (
              <div>
                <p>{desc.description}</p>
                <p>Erstellt von: {desc.email}</p>
                <div className="rating">
                  <button onClick={() => handleVote(desc._id, 'up')}>Upvote</button>
                  <span>{desc.upvotes}</span>
                  <button onClick={() => handleVote(desc._id, 'down')}>Downvote</button>
                  <span>{desc.downvotes}</span>
                </div>
                <button onClick={() => handleEdit(desc._id)}>Edit</button>
                <button onClick={() => handleDelete(desc._id)}>Delete</button>
              </div>
            )}
          </div>
        ))}
        <div className="add-description">
          <textarea 
            value={newDescription} 
            onChange={(e) => setNewDescription(e.target.value)} 
            placeholder="Add a new description"
          />
          <button onClick={handleAddDescription}>Add Description</button>
        </div>
      </div>
    </div>
  );
};

export default DomainDescription;
